import React, { useEffect, useState } from 'react'
import { TableColumn } from 'react-data-table-component';
import usePayrolls from '../../hooks/usePayrolls';
import useReports from '../../hooks/useReports';
import InputDate from '../global/InputDate';
import MyDataTable from '../global/MyDataTable';
import { EllipsisLoader } from '../global/MyLoaders';
import PayrollInfoCard from '../global/PayrollInfoCard';

const PayrollReport = () => {

  const { getPayrollReport, payrollReport, loading, getPayrollReportSearch } = useReports();
  const { getAllStats, payrollStats, getAllStatSearch } = usePayrolls();

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getPayrollReport(), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAllStats(), []);

  const handleDateSelector = (e: any) => {
    const { name, value } = e;
    if (name === 'start_date') {
      setStartDate(value);
    }
    if (name === 'end_date') {
      setEndDate(value);
    }
  }

  const handleFilter = () => {
    getAllStatSearch(startDate, endDate);
    getPayrollReportSearch(startDate, endDate);

  }

  const columnData: TableColumn<any>[] = [
    {
      id: 1,
      name: "Staff Name(s)",
      selector: (row) => `${row?.staff?.user?.first_name} ${row?.staff?.user?.last_name}`,
      reorder: true,
      sortable: true
    },
    {
      id: 2,
      name: "Pay date",
      selector: (row) => row.pay_date,
      reorder: true,
      sortable: true
    },
    {
      id: 3,
      name: "Gross Ammount",
      selector: (row) => Number(row.net_pay).toLocaleString(),
      reorder: true,
      sortable: true
    },
    {
      id: 4,
      name: "Total Deducations",
      selector: (row) => Number(row.deductions).toLocaleString(),
      reorder: true,
      sortable: true
    },
    {
      id: 5,
      name: "Paid Amount",
      selector: (row) => Number(row.paid_amount).toLocaleString(),
      reorder: true,
      sortable: true
    }

  ];
  return (
    <div>
      <div className='form-row'>
        <div className="col-sm-4">
          <InputDate name='start_date' handleChange={handleDateSelector} placeholder="Please provide the start date" />
        </div>
        <div className="col-sm-4">
          <InputDate name='end_date' handleChange={handleDateSelector} placeholder="Please provide the end date" />
        </div>
        <div className="col-sm-3">
          <button className='btn btn-info ' onClick={handleFilter}>Filter</button>
        </div>
      </div>
      <hr />
      <div>
        {/* data table */}
        {loading ? <EllipsisLoader /> : (<>
          {payrollStats.length > 0 && (
            <div className="row justify-content-between m-0">
              <PayrollInfoCard title='Total Gross' amount={payrollStats[0]?.total_pay} />
              <PayrollInfoCard title='Total Deductions' amount={payrollStats[0]?.deductions} icon="fa-minus" bg='danger' />
              <PayrollInfoCard title='Total Net' amount={payrollStats[0]?.net_pay} icon="fa-balance-scale" bg="primary" />
              <PayrollInfoCard title='Total Paid' amount={payrollStats[0]?.paid_amount} icon=" fa-dollar" bg="success" />
            </div>
          )}

          <hr />
          <MyDataTable columns={columnData} data={payrollReport} title="" />
        </>
        )}

      </div>
    </div>
  )
}

export default PayrollReport