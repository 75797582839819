import React, { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import useAccounts from "../../../hooks/useAccounts";
import { baseURLImage } from "../../../utils/axios";
import { sortById } from "../../../utils/helpers";
import ModalSwitcher from "../../global/ModalSwitcher";
import MyDataTable from "../../global/MyDataTable";
import { EllipsisLoader } from "../../global/MyLoaders";
import AddCashFlow from "./AddCashFlow";
import AddExpense from "./AddExpense";
import AddIncome from "./AddIncome";
import ConfirmAction from "../../global/ConfirmAction";
import AddGeneralTransaction from "./AddGeneralTransaction";

const ListTransactions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);

  const { transactions, loading, getTransactions, refreshTransactions } =
    useAccounts();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getTransactions(), []);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  };
  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "Transaction. ID",
      cell: (row) => (
        <Link
          className="text-primary"
          to={`/accounting/transactions/${row.id}`}
        >
          {row.id}
        </Link>
      ),
      wrap: true,
      sortable: true,
      reorder: true,
      sortFunction: sortById,
    },
    {
      id: 2,
      name: "Ref. No",
      selector: (row) => row.ref_no,
      wrap: true,
      grow: 2,
    },
    {
      id: 3,
      name: "Ref. ID",
      selector: (row) => row.ref_id,
      wrap: true,
      grow: 2,
    },
    {
      id: 4,
      name: "Date",
      selector: (row) => row.transaction_date,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 5,
      name: "Narrative ",
      selector: (row) => row.description,
      grow: 3,
      wrap: true,
    },
    {
      id: 6,
      name: "Type ",
      selector: (row) => row.type_name,
      reorder: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 7,
      name: "Amount",
      selector: (row) => Number(row.tt_amount).toLocaleString(),
      reorder: true,
      grow: 2,
      wrap: true,
    },

    {
      button: true,
      cell: (row) => (
        <div className="row">
          {row.journal_type_id != 2 && (
            <a
              href={`${baseURLImage}/transactions/${row.id}/print`}
              rel="noreferrer"
              target={"_blank"}
              className="btn btn-sm btn-primary mx-1"
            >
              <i className="fa fa-print"></i>
            </a>
          )}

          <button
            title="delete transaction"
            className="btn btn-sm btn-danger mx-1"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
              setSelectedId(row.id);
              setOpenModal(3);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 1:
      modalId = "add-expense";
      modalTitle = "Add New Expense";
      ModalToRender = AddExpense;
      break;
    case 2:
      modalId = "add-cashflow";
      modalTitle = "Add New Cash Flow";
      ModalToRender = AddCashFlow;
      break;
    case 3:
      modalId = "delete-transaction";
      modalTitle = "Delete This Transaction?";
      ModalToRender = ConfirmAction;
      break;

    case 4:
      modalId = "add-general-transaction";
      modalTitle = "Add New General Transaction";
      ModalToRender = AddGeneralTransaction;
      break;

    default:
      modalId = "add-new-entry";
      modalTitle = "Add New Income";
      ModalToRender = AddIncome;
      break;
  }

  return (
    <>
      <ModalSwitcher
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={modalId}
        title={modalTitle}
        component={
          <ModalToRender
            closeModal={handleModalClick}
            path={`transactions/${selectedId}/delete`}
            url={`transactions/${selectedId}/delete`}
            refresh={() => refreshTransactions()}
          />
        }
      />
      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className="pl-3 row align-items-end">
                  <button
                    className="btn btn-primary ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                      setOpenModal(0);
                    }}
                  >
                    <i className="fa fa-plus"></i>New Income
                  </button>
                  <button
                    className="btn btn-info ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                      setOpenModal(1);
                    }}
                  >
                    <i className="fa fa-plus"></i>New Expense
                  </button>
                  <button
                    className="btn btn-info ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                      setOpenModal(2);
                    }}
                  >
                    <i className="fa fa-plus"></i>New Cash flow
                  </button>

                  <button
                    className="btn btn-info ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                      setOpenModal(4);
                    }}
                  >
                    <i className="fa fa-plus"></i>General Transaction
                  </button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search...."
                  />
                </div>
              </div>
            </div>
            {/* table */}
            {loading ? (
              <EllipsisLoader />
            ) : (
              <MyDataTable columns={columns} data={transactions} title="" />
            )}

            {/* end table */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListTransactions;
