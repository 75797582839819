import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { accountChartDefaultData, errorToastOptions, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useAccountCategories from '../../../hooks/useAccountCategories';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputSelect from '../../global/InputSelect';
import Textarea from '../../global/Textarea';

interface IAddAccountChartProps {
  closeModal: () => void;
  refresh: () => void;
}

const AddAccountChart = ({ closeModal, refresh }: IAddAccountChartProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [account, setAccount] = useState(accountChartDefaultData);
  const { formattedAccountingCategories } = useAccountCategories();

  const validator = new Validator();

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setAccount({ ...account, ...{ [name]: { ...account[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setAccount({ ...account, ...{ [name]: { ...account[name], ...{ error: true, data: value } } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setAccount({ ...account, ...{ [name]: { ...account[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setAccount({ ...account, ...{ [name]: { ...account[name], ...{ error: true, data: value } } } });
    }
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(account, setAccount)) {
      setFormLoading(true);

      const data = {
        account_name: account.account_name.data,
        // accode_code: account.account_code.data,
        account_no: account.account_no.data,
        description: account.description.data,
        account_sub_category_id: account.account_sub_category_id.data,
        opening_balance: account.opening_balance.data,
      }

      doCreate('account-charts/create', data).then((response) => {
        if (response.data.success === true) {
          toast.success('New account created successfully', successToastOptions);
          refresh();

          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).finally(() => {
        setFormLoading(false);
      })
    } else {
      console.log(account);
    }
  }


  return (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect data={formattedAccountingCategories} label="Category" id='category' name='account_sub_category_id' required handleChange={handleSelectChange} />
        </div>
        <div className="col-sm-6">
          <Input handleChange={handleChange} name="account_name" label='Account name' required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input handleChange={handleChange} name="account_no" label='Account no' required />
        </div>
        <div className="col-sm-6">
          <div className="form-row">
            <div className="col-5">
              <InputSelect data={[{ label: 'Debit', value: 'Dr' }, { label: 'Credit', value: 'Cr' }]} label="Credit/Debit?" id='parent_account' name='account_type' required handleChange={handleSelectChange} />
            </div>
            <div className="col-7">
              <Input handleChange={handleChange} name="balance" label='Opening balance' />
            </div>
          </div>
        </div>
      </div>



      <div className="form-row">
        <div className="col-sm-6">
          <Textarea handleChange={handleChange} name="description" label='Description' required />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>{formLoading ? "Creating..." : "Create"}</button>
      </div>
    </form>
  )
}

export default AddAccountChart