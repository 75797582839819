import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { stockGet } from '../api/stocks';
import { RootState } from '../redux/app/store';
import { setAccountingCategories, setFormattedAccountingCategories } from '../redux/reducer/accounting';
import { formatAccountSubCategorySelectData } from '../utils/helpers';

const useAccountCategories = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    accountCategories, formattedAccountingCategories
  } = useSelector(
    (state: RootState) => state.accounting,
  );

  useEffect(() => {

    if (accountCategories.length > 0) {
      setLoading(false);
      return;
    }

    stockGet('account-subcategories').then((response) => {
      if (response.status === 200) {
        dispatch(setAccountingCategories(response.data.data));
        dispatch(setFormattedAccountingCategories(formatAccountSubCategorySelectData(response.data.data)));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { accountCategories, loading, formattedAccountingCategories }
}

export default useAccountCategories