/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import useSessionStorage from "../hooks/useSessionStorage";
import { ROUTES } from "../routes/routes";

const SideBar = () => {
  const [showId, setShowId] = useState(0);
  const location = useLocation();

  const handleClick = (id: number) => {
    if (id === showId) {
      setShowId(0); // close the dropdown
    } else {
      setShowId(id);
    }
  };

  const { userRoleId } = useSessionStorage();

  return (
    <div className="side-menu-fixed ">
      <div className="scrollbar side-menu-bg">
        <ul className="nav navbar-nav side-menu" id="sidebarnav">
          {/* dashboard */}
          {/* <li
            className={`${location.pathname === ROUTES.DASHBOARD && "active"}`}
          >
            <NavLink to={ROUTES.DASHBOARD}>
              <i className="fa fa-tachometer"></i>
              <span className="right-nav-text">Dashboard </span>
            </NavLink>
          </li> */}

          {/* reports */}
          {[1, 3, 4, 6, 8, 9].includes(userRoleId()) && (
            <li
              className={`${
                location.pathname.startsWith(ROUTES.REPORTS) && "active"
              }`}
            >
              <NavLink to={ROUTES.REPORTS}>
                <i className="fa fa-line-chart"></i>
                <span className="right-nav-text">Reports </span>
              </NavLink>
            </li>
          )}

          {/* Accounting */}
          {[1, 3].includes(userRoleId()) && (
            <li
              className={`${
                location.pathname.startsWith(ROUTES.ACCOUNTING) && "active"
              }`}
            >
              <Link
                to={"#"}
                data-toggle="collapse"
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(5);
                }}
              >
                <div className="pull-left">
                  <i className="fa fa-bank "></i>
                  <span className="right-nav-text">Accounting</span>
                </div>
                <div className="pull-right">
                  <i className="ti-plus"></i>
                </div>
                <div className="clearfix"></div>
              </Link>
              <ul
                className={`collapse ${showId === 5 && "show"}`}
                data-parent="#sidebarnav"
              >
                <li>
                  <Link to={ROUTES.SUBCATEGORIES}>Sub Categories</Link>
                </li>
                <li>
                  <Link to={ROUTES.COA}>Charts of accounts</Link>
                </li>

                <li>
                  <Link to={ROUTES.TRANSACTIONS}>Transactions</Link>
                </li>
                <li>
                  <Link to={ROUTES.BUDGET}>Budgets</Link>
                </li>
                <>
                  <li>
                    <Link to={ROUTES.BILLING}>Billing</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.INVOICES}>Invoices</Link>
                  </li>
                </>
              </ul>
            </li>
          )}

          {/* projects */}
          {/* {[1, 3, 4, 9].includes(userRoleId()) && (
            <li className={`${location.pathname.startsWith(ROUTES.PROJECTS) && 'active'}`}>
              <NavLink to={ROUTES.PROJECTS}><i className="fa fa-suitcase"></i><span className="right-nav-text">Projects </span></NavLink>
            </li>
          )} */}

          {/* human resource */}
          {[1, 4].includes(userRoleId()) && (
            <li
              className={`${
                location.pathname.startsWith(ROUTES.HR) && "active"
              }`}
            >
              <Link
                to="#"
                data-toggle="collapse"
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(7);
                }}
              >
                <div className="pull-left">
                  <i className="fa fa-user-o"></i>
                  <span className="right-nav-text">Human resource</span>
                </div>
                <div className="pull-right">
                  <i className="ti-plus"></i>
                </div>
                <div className="clearfix"></div>
              </Link>
              <ul
                className={`collapse ${showId === 7 && "show"}`}
                data-parent="#sidebarnav"
              >
                <li>
                  <Link to={ROUTES.EMPLOYEES}>Employee</Link>
                </li>
                <li>
                  <Link to={ROUTES.LEAVEMANAGEMENT}>Leave Management</Link>
                </li>
                <>
                  {/* disabled this for now */}
                  {false && (
                    <li>
                      <Link to={ROUTES.STAFFPERFORMANCE}>Performance</Link>
                    </li>
                  )}
                </>
                <li>
                  <Link to={ROUTES.PAYROLLMANAGEMENT}>PayRoll Management</Link>
                </li>
              </ul>
            </li>
          )}

          {/* Asset management */}
          {[1, 3].includes(userRoleId()) && (
            <li
              className={`${
                location.pathname.startsWith(ROUTES.ASSETS) && "active"
              }`}
            >
              <NavLink to={ROUTES.ASSETS}>
                <i className="fa fa-tasks"></i>
                <span className="right-nav-text">Asset Management </span>
              </NavLink>
            </li>
          )}

          {/* stocks */}
          {[1, 3].includes(userRoleId()) && (
            <li
              className={`${
                location.pathname.startsWith(ROUTES.STOCKS) && "active"
              }`}
            >
              <NavLink to={ROUTES.STOCKS}>
                <i className="fa fa-exchange"></i>
                <span className="right-nav-text">Stocks </span>
              </NavLink>
            </li>
          )}

          {/* procurement management */}
          {[1, 3].includes(userRoleId()) && (
            <li
              className={`${
                location.pathname.startsWith(ROUTES.PROCUREMENT) && "active"
              }`}
            >
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(9);
                }}
                to="#"
                data-toggle="collapse"
              >
                <div className="pull-left">
                  <i className="fa fa-product-hunt"></i>
                  <span className="right-nav-text">Procurement</span>
                </div>
                <div className="pull-right">
                  <i className="ti-plus"></i>
                </div>
                <div className="clearfix"></div>
              </Link>
              {[1, 3].includes(userRoleId()) && (
                <ul
                  className={`collapse ${showId === 9 && "show"}`}
                  data-parent="#sidebarnav"
                >
                  <li>
                    <Link to={ROUTES.QUOTATION}>Quotation</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.BIDANALYSIS}>Bid Analysis</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.PURCHASEORDER}>Purchase Order</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.GOODSRECIEVED}>Goods Recieved</Link>
                  </li>
                </ul>
              )}
            </li>
          )}

          <li
            className={`${
              location.pathname === ROUTES.CASHREQUISITIONS && "active"
            }`}
          >
            <NavLink to={ROUTES.CASHREQUISITIONS}>
              <i className="fa fa-money"></i>
              <span className="right-nav-text">Cash Request </span>
            </NavLink>
          </li>

          <li
            className={`${
              location.pathname === ROUTES.REQUISITIONS && "active"
            }`}
          >
            <NavLink to={ROUTES.REQUISITIONS}>
              <i className="fa fa-shopping-basket"></i>
              <span className="right-nav-text">Requisitions </span>
            </NavLink>
          </li>

          {/* settings */}
          <li
            className={`${
              location.pathname.startsWith(ROUTES.SETTINGS) && "active"
            }`}
          >
            <NavLink to={ROUTES.MYPROFILE}>
              <i className="fa fa-cog"></i>
              <span className="right-nav-text">Settings </span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
