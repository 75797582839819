import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import useAccounts from "../../../hooks/useAccounts";
import { EllipsisLoader } from "../../global/MyLoaders";

const ListIncomeStatement = () => {
  const {
    loading,
    incomeStatement,
    getIncomeStatement,
    incomeStatementExpense,
    reportStartDate,
    reportEndDate,
  } = useAccounts();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getIncomeStatement(), [reportStartDate, reportEndDate]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return loading ? (
    <EllipsisLoader />
  ) : (
    <>
      <div className="col-xl-12 mb-30">
        <div className="row mx-0 mb-3 justify-content-end">
          <div className="col-xs-12 col-md-3">
            <div className="row justify-content-end">
              <label>Export</label>
              <button
                onClick={handlePrint}
                className="ml-3 btn btn-sm btn-secondary"
              >
                <i className="fa fa-print" />
              </button>
            </div>
          </div>
        </div>
        <table className="table" ref={componentRef}>
          <thead>
            <tr>
              <th className="bg-info text-center text-white py-2" colSpan={3}>
                Income statement
              </th>
            </tr>
            <tr>
              <th colSpan={2} className="font-bold">
                Income
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {incomeStatement?.map((item: any) => (
              <React.Fragment key={item.id}>
                <tr>
                  <td colSpan={2} className="font-medium">
                    <Link to={`reports/transaction/${item.id}`}>
                      {item.sub_cat_name}
                    </Link>
                  </td>
                  <td className="font-medium">
                    {Number(item.amount).toLocaleString()}
                  </td>
                </tr>
                {item?.accounts?.map((account: any) => (
                  <tr key={account?.id}>
                    <td colSpan={2} className="pl-40">
                      <Link
                        className="text-info"
                        to={`reports/transaction/${account.id}`}
                      >
                        {account?.account_name}
                      </Link>
                    </td>
                    <td>
                      {Math.abs(
                        Number(account?.credit_balance)
                      ).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
            <tr>
              <th colSpan={2} className="font-bold">
                Expenses
              </th>
              <th></th>
            </tr>
            {incomeStatementExpense?.map((item: any) => (
              <React.Fragment key={item.id}>
                <tr>
                  <td colSpan={2} className="font-medium">
                    {item.sub_cat_name}
                  </td>
                  <td className="font-medium">
                    {Number(item.amount).toLocaleString()}
                  </td>
                </tr>
                {item?.accounts?.map((account: any) => (
                  <tr key={account?.id}>
                    <td colSpan={2} className="pl-40">
                      <Link
                        className="text-info"
                        to={`reports/transaction/${account.id}`}
                      >
                        {account?.account_name}
                      </Link>
                    </td>
                    <td>
                      {Math.abs(
                        Number(account?.credit_balance)
                      ).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ListIncomeStatement;
