import React from 'react'
import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import usePayrolls from '../../../hooks/usePayrolls';
import { baseURLImage } from '../../../utils/axios';
import MyDataTable from '../../global/MyDataTable';
import { EllipsisLoader } from '../../global/MyLoaders';

const PayrollTable = () => {
  const { payrolls, loading } = usePayrolls();

  const columnData: TableColumn<any>[] = [
    {
      id: 1,
      name: "Staff Name(s)",
      selector: (row) => `${row?.staff?.user?.first_name} ${row?.staff?.user?.last_name}`,
      reorder: true,
      sortable: true
    },
    {
      id: 2,
      name: "Last Pay date",
      selector: (row) => row.pay_date,
      reorder: true,
      sortable: true
    },
    {
      id: 3,
      name: "Last Gross Ammount",
      selector: (row) => Number(row.net_pay).toLocaleString(),
      reorder: true,
      sortable: true
    },
    {
      id: 4,
      name: "Last Total Deducations",
      selector: (row) => Number(row.deductions).toLocaleString(),
      reorder: true,
      sortable: true
    },
    {
      id: 5,
      name: "Last Paid Amount",
      selector: (row) => Number(row.paid_amount).toLocaleString(),
      reorder: true,
      sortable: true
    },
    {
      id: 6,
      name: "Last Payslip",
      selector: (row) => <a target={'_blank'} rel="noreferrer" href={`${baseURLImage}/payrolls/${row.id}/print`} className='btn btn-sm btn-info'><i className='fa fa-file-pdf-o'></i></a>,
      reorder: true,
      sortable: true
    },
    {
      button: true,
      cell: (row) => (
        <div className='row'>
          <Link to={`/hr/payroll-management/view/${row.staff_id}`} className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></Link>
        </div>
      )
    }
  ];
  return (
    <div className="table-responsive mt-15">

      {loading ? (<EllipsisLoader />) : (
        <MyDataTable data={payrolls} columns={columnData} title="" />

      )}
    </div>
  )
}

export default PayrollTable