import React, { useEffect } from "react";
import { TableColumn } from "react-data-table-component";
import useAccounts from "../../../hooks/useAccounts";
import { useLastUrlSegment } from "../../../hooks/useQuery";
import MyDataTable from "../../global/MyDataTable";
import { EllipsisLoader } from "../../global/MyLoaders";

const View = () => {
  const id = useLastUrlSegment();

  const { getTransactionDetails, selectedTransaction, loading } = useAccounts();

  useEffect(() => getTransactionDetails(id), [id]);

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "#",
      selector: (row) => row.id,
      reorder: true,
      sortable: true,
    },
    {
      id: 2,
      name: "Account Name",
      selector: (row) => row.account?.account_name,
      reorder: true,
      sortable: true,
    },
    {
      id: 3,
      name: "Date",
      selector: (row) => row.transaction_date,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 4,
      name: "Narrative",
      selector: (row) => row.narrative,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 5,
      name: "Debit",
      selector: (row) =>
        row.debit_amount
          ? Number(row.debit_amount).toLocaleString()
          : row.debit_amount,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 6,
      name: "Credit",
      selector: (row) =>
        row.credit_amount
          ? Number(row.credit_amount).toLocaleString()
          : row.credit_amount,
      reorder: true,
      sortable: true,
      wrap: true,
    },
  ];

  return loading ? (
    <EllipsisLoader />
  ) : (
    <div>
      <div className="card p-3 mb-10">
        <table className="table table-bordered table-striped">
          <tr>
            <th>Transaction No.</th>
            <td>{selectedTransaction?.unique_id}</td>
            <th>Ref No.</th>
            <td>{selectedTransaction?.ref_no}</td>
          </tr>

          <tr>
            <th>Budget</th>
            <td>{selectedTransaction?.budget?.name}</td>
            <th>Ref ID</th>
            <td>{selectedTransaction?.ref_id}</td>
          </tr>
          <tr>
            <th>Date</th>
            <td>{selectedTransaction?.transaction_date}</td>
            <th>Amount</th>
            <td>
              {Number(
                selectedTransaction?.journal_line
                  ? selectedTransaction?.journal_line[0]?.debit_amount
                  : 0
              ).toLocaleString()}
            </td>
          </tr>

          <tr>
            <th>Note</th>
            <td colSpan={3}>{selectedTransaction?.description}</td>
          </tr>
        </table>
      </div>

      <div className="card p-3 mb-10">
        <h5>Transactions List</h5>
        <MyDataTable
          columns={columns}
          data={selectedTransaction?.journal_line}
          title=""
        />
      </div>
    </div>
  );
};

export default View;
