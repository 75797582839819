import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useProjects from '../../../hooks/useProjects'
import { useLastUrlSegment } from '../../../hooks/useQuery'
import Card from '../../../parts/Card'
import { ROUTES } from '../../../routes/routes'
import CardContentWrapper from '../../global/CardContentWrapper'
import CardHeaderWithBottomBorder from '../../global/CardHeaderWithBottomBorder'
import MyModal from '../../global/MyModal'
import AddBudget from './AddBudget'

const Left = () => {
  const { selectedProject, fetchSingleProject } = useProjects();
  const [isOpen, setIsOpen] = useState(false);
  const id = useLastUrlSegment();

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }
  return (
    <>
      <MyModal modalOpen={isOpen} setModalOpen={setIsOpen} id="add-new-project-budget" title="Add new project budget">
        <AddBudget id={id} onCancel={handleModalClick} refresh={() => fetchSingleProject(id)} />
      </MyModal>
      <div className='col-sm-8'>
        <div className="row">
          <div className="col-sm-6">
            <Card>
              <>
                <CardHeaderWithBottomBorder title={selectedProject.project_name} />
                <CardContentWrapper>
                  <div className='row'>
                    <div className="col-sm-12">
                      <h6>Implementing partner</h6>
                      <p>{selectedProject.implementing_partner}</p>
                    </div>
                  </div>
                </CardContentWrapper>
                <hr />
                <CardContentWrapper>
                  <div className='row'>
                    <div className="col-sm-4">
                      <h6>Location</h6>
                      <p>{selectedProject.location}</p>
                    </div>

                    <div className="col-sm-4">
                      <h6>Section</h6>
                      <p>{selectedProject.sector}</p>
                    </div>
                    <div className="col-sm-4">
                      <h6>Level</h6>
                      <p>{selectedProject.level}</p>
                    </div>
                  </div>
                </CardContentWrapper>
              </>

            </Card>
          </div>
          <div className="col-sm-6">
            <Card>
              <>
                <CardHeaderWithBottomBorder title="Project budget" />
                <CardContentWrapper>
                  <div className='row'>
                    <div className="col-sm-12">
                      {selectedProject.project_budget ? (
                        <>
                          <div className="table-responsive">
                            <table className='table table-bordered'>
                              <thead>
                                <tr>
                                  <th>Amount</th>
                                  <th>Parent Budget</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{Number(selectedProject.project_budget?.est_amount).toLocaleString()}</td>
                                  <td><Link className='text-primary' to={`/accounting/budget/view/${selectedProject.project_budget?.id}`}>{selectedProject.project_budget?.budget?.name}</Link></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {selectedProject.funders.length > 0 && (
                            <>
                              <h5>Funder(s)</h5>
                              <ul>
                                {selectedProject.funders.map((funder: any) => (
                                  <li key={funder?.id}><Link className='text-info' to={ROUTES.FUNDERS}>{funder?.name}</Link></li>
                                ))}
                              </ul>
                            </>
                          )

                          }
                        </>

                      ) : (
                        <p className='text-muted'>No budget yet, please <span className='border-bottom-dashed-link text-info pointer' onClick={handleModalClick}>click here</span> to add a project budget</p>
                      )}
                    </div>
                  </div>
                </CardContentWrapper>
              </>

            </Card>
          </div>
        </div>
        <Card>
          <>
            <CardContentWrapper>
              <>
                <h6>Activity Evaluation</h6>
                <p>{selectedProject.activity_evaluation}</p>
              </>
            </CardContentWrapper>
            <CardContentWrapper>
              <>
                <h6>Challenges</h6>
                <p>{selectedProject.challenges}</p>

              </>
            </CardContentWrapper>
            <CardContentWrapper>
              <>
                <h6>Recommendation</h6>
                <p>{selectedProject.recommendations}</p>

              </>
            </CardContentWrapper>
          </>
        </Card>
      </div>
    </>
  )
}

export default Left