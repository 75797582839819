import { HTMLReactParserOptions } from "html-react-parser";
import moment from "moment";
import { iFormDefaultData } from "./interfaces/interfaces";
export const ERRORMESSAGE =
  "Something went wrong, please contact support for further assistance";

export const errorToastOptions = {
  duration: 6000,
  style: {
    border: "1px solid #9B2915",
    padding: "16px",
    color: "#713200",
  },
  iconTheme: {
    primary: "#713200",
    secondary: "#FFFAEE",
  },
};

export const successToastOptions = {
  duration: 3000,
  style: {
    border: "1px solid #50209b",
    padding: "16px",
    color: "#50209b",
  },
  iconTheme: {
    primary: "#50209b",
    secondary: "#FFFAEE",
  },
};

export const personalDetails = [
  {
    id: 1,
    pay_date: "2022/05/20",
    gross: 500000,
    deducation: 0,
    net_pay: 500000,
    paid: 500000,
    recurring: "No",
  },
  {
    id: 2,
    pay_date: "2022/04/20",
    gross: 500000,
    deducation: 400000,
    net_pay: 400000,
    paid: 400000,
    recurring: "No",
  },
];

export const storyDefaultData: iFormDefaultData = {
  name: { error: false, data: "", required: true },
  age: { error: false, data: "", required: true },
  address: { error: false, data: "", required: true },
  gender: { error: false, data: "Male", required: false },
  story: { error: false, data: "", required: true },
  title: { error: false, data: "", required: true },
};

export const leaveDefaultData: iFormDefaultData = {
  staff_id: { error: false, data: "", required: true },
  leave_type: { error: false, data: "", required: true },
  days_applied_for: { error: false, data: "", required: true },
  start_date: { error: false, data: "", required: true },
  end_date: { error: false, data: "", required: true },
  resumption_date: { error: false, data: "", required: true },
  contact_address: { error: false, data: "", required: true },
  comment: { error: false, data: "", required: true },
};

export const funFactDefaultData: iFormDefaultData = {
  description: { error: false, data: "", required: true },
  url: { error: false, data: "", required: true },
  title: { error: false, data: "", required: true },
};

export const healthTipDefaultData: iFormDefaultData = {
  description: { error: false, data: "", required: true },
  title: { error: false, data: "", required: true },
};

export const assetDefaultData: iFormDefaultData = {
  asset_account_id: { error: false, data: "", required: true },
  asset_name: { error: false, data: "", required: true },
  identity_no: { error: false, data: "", required: true },
  purchase_date: { error: false, data: "", required: true },
  purchase_cost: { error: false, data: "", required: true },
  date_when: { error: false, data: "", required: true },
  depreciation_account_id: { error: false, data: "", required: false },
  depreciation_loss_account_id: { error: false, data: "", required: false },
  depreciation_gain_account_id: { error: false, data: "", required: false },
  expense_account_id: { error: false, data: "", required: false },
  depreciation_method_id: { error: false, data: "", required: false },
  depreciation_rate: { error: false, data: "", required: false },
  depre_appre_id: { error: false, data: "0", required: false },
  income_account_id: { error: false, data: "", required: false },
  appreciation_account_id: { error: false, data: "", required: false },
  appreciation_loss_account_id: { error: false, data: "", required: false },
  appreciation_gain_account_id: { error: false, data: "", required: false },
  appreciation_rate: { error: false, data: "", required: false },
  salvage_value: { error: false, data: "", required: true },
  expected_age: { error: false, data: "", required: false },
  description: { error: false, data: "", required: true },
  budget_id: { error: false, data: "", required: true },
};

export const assetPaymentDefaultData: iFormDefaultData = {
  transaction_date: { error: false, data: "", required: true },
  amount: { error: false, data: "", required: true },
  account_chart_id: { error: false, data: "", required: true },
  narrative: { error: false, data: "", required: true },
};
export const assetAppreciationDefaultData: iFormDefaultData = {
  transaction_date: { error: false, data: "", required: true },
  amount: { error: false, data: "", required: true },
  narrative: { error: false, data: "", required: true },
};

export const assetIncomeDefaultData: iFormDefaultData = {
  transaction_date: { error: false, data: "", required: true },
  amount: { error: false, data: "", required: true },
  narrative: { error: false, data: "", required: true },
  account_chart_id: { error: false, data: "", required: true },
  asset_income_type_id: { error: false, data: "", required: true },
};

export const assetExpenseDefaultData: iFormDefaultData = {
  transaction_date: { error: false, data: "", required: true },
  amount: { error: false, data: "", required: true },
  narrative: { error: false, data: "", required: true },
  asset_expense_type_id: { error: false, data: "", required: true },
  fund_source_account_id: { error: false, data: "", required: true },
  expense_account_id: { error: false, data: "", required: true },
};

export const assetTypeDefaultData: iFormDefaultData = {
  name: { error: false, data: "", required: true },
  asset_categories_id: { error: false, data: "", required: true },
};

export const treatmentDefaultData: iFormDefaultData = {
  patient_id: { error: false, data: "", required: true },
  form: { error: false, data: "", required: true },
  arv_type_id: { error: false, data: "", required: true },
  dosage: { error: false, data: "", required: true },
  instructions: { error: false, data: "", required: true },
  period: { error: false, data: "", required: true },
  frequency: { error: false, data: "", required: true },
  treatment_start_date: { error: false, data: "", required: true },
  refill_date: { error: false, data: "", required: true },
  treatment_end_date: { error: false, data: "", required: false },
  medication_time: { error: false, data: "", required: true },
  next_appointment_date: { error: false, data: "", required: true },
};

export const stockDefaultData: iFormDefaultData = {
  name: { error: false, data: "", required: true },
  quantity: { error: false, data: "", required: true },
  total_price: { error: false, data: "", required: true },
  bought_on: { error: false, data: "", required: true },
  account_chart_id: { error: false, data: "", required: true },
  budget_id: { error: false, data: "", required: true },
  stock_account: { error: false, data: "", required: true },
};

export const supplierDefaultData: iFormDefaultData = {
  name: { error: false, data: "", required: true },
  email: { error: false, data: "", required: true },
  telephone: { error: false, data: "", required: true },
  location: { error: false, data: "", required: true },
};

export const brandDefaultData: iFormDefaultData = {
  name: { error: false, data: "", required: true },
  description: { error: false, data: "", required: true },
};

export const stockCategoryDefaultData: iFormDefaultData = {
  name: { error: false, data: "", required: true },
  description: { error: false, data: "", required: true },
};

export const stockProductDefaultData: iFormDefaultData = {
  name: { error: false, data: "", required: true },
  reference: { error: false, data: "", required: true },
  price: { error: false, data: "", required: true },
  vat: { error: false, data: "", required: true },
  project_category_id: { error: false, data: "", required: true },
  brand_id: { error: false, data: "", required: true },
  supplier_id: { error: false, data: "", required: true },
  account_chart_id: { error: false, data: "", required: true },
  budget_id: { error: false, data: "", required: true },
  product_account: { error: false, data: "", required: true },
};

export const outGoingStockDefaultData: iFormDefaultData = {
  taken_at: { error: false, data: "", required: true },
  description: { error: false, data: "", required: true },
  quantity: { error: false, data: "", required: true },
  stock_id: { error: false, data: "", required: true },
  department_id: { error: false, data: "", required: true },
};

export const clinicDefaultData: iFormDefaultData = {
  name: { error: false, data: "", required: true },
  telephone: { error: false, data: "", required: false },
  location: { error: false, data: "", required: true },
  licence_no: { error: false, data: "", required: false },
  email: { error: false, data: "", required: false },
};

export const paymentTypeDefaultData: iFormDefaultData = {
  name: { error: false, data: "", required: true },
  description: { error: false, data: "", required: true },
  linked_account_id: { error: false, data: "", required: true },
};

export const invoicePaymentDefaultData: iFormDefaultData = {
  total_paid: { error: false, data: "0", required: true },
  grand_total: { error: false, data: "0", required: true },
  due_amount: { error: false, data: "0", required: true },
  date_paid: {
    error: false,
    data: moment().format("YYYY-MM-DD "),
    required: true,
  },
  payment_type_id: { error: false, data: "", required: true },
  expense_account_id: { error: false, data: "", required: true },
};

export const invoiceDefaultData: iFormDefaultData = {
  client_id: { error: false, data: "", required: true },
  client_type: { error: false, data: "1", required: true },
  serial_no: { error: false, data: "", required: true },
  payment_status: { error: false, data: "", required: true },
  invoiced_on: {
    error: false,
    data: moment().format("YYYY-MM-DD "),
    required: false,
  },
  due_date: {
    error: false,
    data: moment().format("YYYY-MM-DD "),
    required: false,
  },
  sub_total: { error: false, data: "", required: false },
  note: { error: false, data: "", required: true },
  terms_and_conditions: { error: false, data: "", required: true },
  paid_amount: { error: false, data: "", required: false },
  payment_type_id: { error: false, data: "", required: false },
  discount: { error: false, data: "0", required: false },
};

export const funderDefaultData: iFormDefaultData = {
  name: { error: false, data: "", required: true },
  telephone: { error: false, data: "", required: true },
  amount: { error: false, data: "", required: true },
  email: { error: false, data: "", required: false },
  telephone_2: { error: false, data: "", required: false },
};

export const budgetDefaultData: iFormDefaultData = {
  start_date: { error: false, data: "", required: true },
  end_date: { error: false, data: "", required: true },
  exp_amount: { error: false, data: "", required: true },
  name: { error: false, data: "", required: true },
  budget_id: { error: false, data: "", required: false },
  is_parent: { error: false, data: "0", required: false },
};

export const payBillDefaultData: iFormDefaultData = {
  payment_channel: { error: false, data: "", required: true },
  budget_id: { error: false, data: "", required: false },
  debit: { error: false, data: "", required: false },
  bill_amount: { error: false, data: "", required: false },
  status_id: { error: false, data: "", required: false },
  phone_number: { error: false, data: "", required: false },
};

export const billDefaultData: iFormDefaultData = {
  name: { error: false, data: "", required: true },
  bill_amount: { error: false, data: "", required: true },
  status_id: { error: false, data: "", required: false },
  payment_channel: { error: false, data: "", required: true },
  user_type: { error: false, data: "", required: true },
  credit: { error: false, data: "", required: false },
  debit: { error: false, data: "", required: false },
  employee_id: { error: false, data: "", required: false },
  supplier_id: { error: false, data: "", required: false },
  phone_number: { error: false, data: "", required: false },
};

export const accountChartDefaultData: iFormDefaultData = {
  account_sub_category_id: { error: false, data: "", required: true },
  parent_account_id: { error: false, data: "", required: false },
  account_name: { error: false, data: "", required: true },
  account_no: { error: false, data: "", required: true },
  opening_balance: { error: false, data: "", required: false },
  description: { error: false, data: "", required: false },
  account_type: { error: false, data: "", required: true },
};

export const subCategoryDefaultData: iFormDefaultData = {
  account_category_id: { error: false, data: "", required: true },
  sub_cat_name: { error: false, data: "", required: true },
  sub_cat_code: { error: false, data: "", required: true },
  description: { error: false, data: "", required: false },
};

export const transactionDefaultData: iFormDefaultData = {
  account_chart_id: { error: false, data: "", required: true },
  narrative: { error: false, data: "", required: true },
  transaction_date: {
    error: false,
    data: new Date().toString(),
    required: true,
  },
  ref_no: { error: false, data: "", required: true },
  amount: { error: false, data: "0", required: true },
  debit_account_chart_id: { error: false, data: "", required: true },
  description: { error: false, data: "", required: true },
  budget_id: { error: false, data: "", required: false },
};

export const patientDefaultData: iFormDefaultData = {
  first_name: { error: false, data: "", required: true },
  last_name: { error: false, data: "", required: true },
  address: { error: false, data: "", required: true },
  date_of_birth: { error: false, data: "", required: true },
  gender: { error: false, data: "", required: true },
  telephone: { error: false, data: "", required: true },
  district_id: { error: false, data: "", required: true },
  pin: { error: false, data: "", required: true },

  nox_first_name: { error: false, data: "", required: true },
  nox_last_name: { error: false, data: "", required: true },
  nox_telephone: { error: false, data: "", required: true },
  nox_gender: { error: false, data: "", required: true },
  nox_address: { error: false, data: "", required: true },
  nox_district: { error: false, data: "", required: true },
};

export const depreciationAppreciation: Array<any> = [
  { value: "2", label: "Appreciation" },
  { value: "1", label: "Depreciation" },
  { value: "0", label: "Not Applicable" },
];

export const depreciationMethod: Array<any> = [
  { value: "1", label: "Straight-line method" },
  { value: "2", label: "Double-Declining method" },
];

export const languagesData: Array<any> = [
  { value: "English", label: "English" },
  { value: "Swahili", label: "Swahili" },
  { value: "Runyankole/Rukiga", label: "Runyankole/Rukiga" },
  { value: "Luganda", label: "Luganda" },
  { value: "Lusoga", label: "Lusoga" },
  { value: "Others", label: "Others" },
];

export const treatmentForm: Array<any> = [
  {
    label: "Tablets",
    value: "Tablets",
  },
  {
    label: "Injections",
    value: "Injections",
  },
];

export const reminderUnits: Array<any> = [
  {
    label: "Minutes before",
    value: "Minutes before",
  },
  {
    label: "Hours before",
    value: "Hours before",
  },
  {
    label: "Days before",
    value: "Days before",
  },
  {
    label: "Weeks before",
    value: "Weeks before",
  },
];

export const treatmentFrequency: Array<any> = [
  {
    label: "Once",
    value: "1",
  },
  {
    label: "Twice",
    value: "2",
  },
  {
    label: "Thrice",
    value: "3",
  },
];

export const educationDetail: Array<any> = [
  { value: "None", label: "None" },
  { value: "Primary", label: "Primary" },
  { value: "Secondary", label: "Secondary" },
  { value: "Tertiary", label: "Tertiary" },
  { value: "Graduate", label: "Graduate" },
  { value: "Postgraduate", label: "Postgraduate" },
];

export const treatmentPeriod: Array<any> = [
  {
    label: "Daily",
    value: "Daily",
  },
];

export const statusSelectData: Array<any> = [
  {
    label: "Pending",
    value: "1",
  },
  {
    label: "Paid out",
    value: "2",
  },
  {
    label: "Declined",
    value: "3",
  },
];

export const userTypeSelectData: Array<any> = [
  {
    label: "Employee",
    value: "1",
  },
  {
    label: "Supplier",
    value: "2",
  },
];

export const paymentStatuses: Array<any> = [
  {
    label: "Paid",
    value: "1",
  },
  {
    label: "Unpaid",
    value: "2",
  },
  {
    label: "Partially paid",
    value: "3",
  },
  {
    label: "Overdue",
    value: "4",
  },
];

export const paymentMethodSelectData: Array<any> = [
  {
    label: "Cash",
    value: "1",
  },
  {
    label: "Mobile Money",
    value: "2",
  },
  {
    label: "Bank",
    value: "3",
  },
];

export const priorityData: Array<any> = [
  {
    label: "Low",
    value: "1",
  },
  {
    label: "Medium",
    value: "2",
  },
  {
    label: "Highest",
    value: "3",
  },
];

export const statusData: Array<any> = [
  {
    label: "Pending",
    value: "Pending",
  },
  {
    label: "On-going",
    value: "On-going",
  },
  {
    label: "Suspended",
    value: "Suspended",
  },
  {
    label: "Completed",
    value: "Completed",
  },
];

export const projectDefaultData: iFormDefaultData = {
  project_name: { required: true, error: false, data: "" },
  implementing_partner: { required: true, error: false, data: "" },
  location: { required: true, error: false, data: "" },
  cost: { required: true, error: false, data: "" },
  activity_evaluation: { required: true, error: false, data: "" },
  project_category_id: { required: true, error: false, data: "" },
  reporting_period: { required: true, error: false, data: "" },
  level: { required: true, error: false, data: "" },
  sector: { required: true, error: false, data: "" },
  recommendations: { required: true, error: false, data: "" },
  challenges: { required: true, error: false, data: "" },
};

export const userDefaultData: iFormDefaultData = {
  first_name: { required: true, error: false, data: "" },
  middle_name: { required: false, error: false, data: "" },
  last_name: { required: true, error: false, data: "" },
  email: { required: true, error: false, data: "" },
  mobile_phone: { required: true, error: false, data: "" },
  role_id: { required: true, error: false, data: "" },
  languages: { required: true, error: false, data: "" },
  date_of_birth: { required: true, error: false, data: "" },
  profile_pic: { required: false, error: false, data: "" },
  gender: { required: true, error: false, data: "" },
  username: { required: true, error: false, data: "" },
  password: { required: true, error: false, data: "" },
};

export const employeeEducationDefaultData: iFormDefaultData = {
  school: { required: true, error: false, data: "" },
  award: { required: true, error: false, data: "" },
  end_year: { required: true, error: false, data: "" },
  from_year: { required: true, error: false, data: "" },
};

export const requisitionDefaultData: iFormDefaultData = {
  request_date: { required: true, error: false, data: "" },
  department_id: { required: true, error: false, data: "" },
  staff_id: { required: false, error: false, data: "" },
  items: { required: false, error: false, data: "" },
  delivery_date: { required: true, error: false, data: "" },
  description: { required: true, error: false, data: "" },
};

export const cashRequisitionDefaultData: iFormDefaultData = {
  request_date: { required: true, error: false, data: "" },
  amount: { required: true, error: false, data: "" },
  reason: { required: true, error: false, data: "" },
  delivery_date: { required: true, error: false, data: "" },
  description: { required: false, error: false, data: "" },
};

export const projectBudgetDefaultData: iFormDefaultData = {
  funders: { required: false, error: false, data: "" },
  est_amount: { required: true, error: false, data: "" },
  budget_id: { required: true, error: false, data: "" },
};

export const employeeEmploymentDefaultData: iFormDefaultData = {
  employer: { required: true, error: false, data: "" },
  position: { required: true, error: false, data: "" },
  end_date: { required: true, error: false, data: "" },
  start_date: { required: true, error: false, data: "" },
};

export const employeeRefereeDefaultData: iFormDefaultData = {
  names: { required: true, error: false, data: "" },
  occupation: { required: true, error: false, data: "" },
  telephone: { required: true, error: false, data: "" },
  email: { required: true, error: false, data: "" },
};

export const employeeOtherTrainingDefaultData: iFormDefaultData = {
  award: { required: true, error: false, data: "" },
  date_attained: { required: true, error: false, data: "" },
  institution: { required: true, error: false, data: "" },
  nature_of_training: { required: true, error: false, data: "" },
};

export const employeeAddressDefaultData: iFormDefaultData = {
  county: { required: true, error: false, data: "" },
  district: { required: true, error: false, data: "" },
  sub_county: { required: true, error: false, data: "" },
  village: { required: true, error: false, data: "" },
  resident_town: { required: true, error: false, data: "" },
  resident_village: { required: true, error: false, data: "" },
  resident_phone: { required: true, error: false, data: "" },
};

export const employeeFinalDefaultData: iFormDefaultData = {
  department_id: { required: true, error: false, data: "" },
  user_id: { required: true, error: false, data: "" },
  job_title: { required: true, error: false, data: "" },
  work_id: { required: true, error: false, data: "" },
  duty_station: { required: true, error: false, data: "" },
  salary: { required: true, error: false, data: "" },
};

export const employeePersonalDefaultData: iFormDefaultData = {
  account_no: { required: true, error: false, data: "" },
  bank: { required: true, error: false, data: "" },
  convicted: { required: false, error: false, data: "false" },
  conviction_details: { required: false, error: false, data: "" },
  // department_id: { required: true, error: false, data: '' },
  disabled: { required: false, error: false, data: "false" },
  disability_details: { required: false, error: false, data: "" },
  // duty_station: { required: true, error: false, data: '' },
  discharged_from_duty: { required: false, error: false, data: "false" },
  discharge_reason: { required: false, error: false, data: "" },
  highest_qualification: { required: true, error: false, data: "" },
  hospitalized: { required: false, error: false, data: "" },
  hospitalized_details: { required: false, error: false, data: "" },
  // job_title: { required: true, error: false, data: '' },
  national_id_no: { required: true, error: false, data: "" },
  next_of_keen: { required: false, error: false, data: "" },
  no_of_children: { required: false, error: false, data: "0" },
  nssf_no: { required: false, error: false, data: "" },
  passport_no: { required: false, error: false, data: "" },
  place_of_birth: { required: true, error: false, data: "" },
  // salary: { required: true, error: false, data: '' },
  // social_membership: { required: true, error: false, data: '' },
  spouse_name: { required: false, error: false, data: "" },
  // tin_no: { required: false, error: false, data: '' },
  // work_id: { required: true, error: false, data: '' },
  children: { required: false, error: false, data: "" },
  date_of_first_appointment: {
    required: true,
    error: false,
    data: moment().toLocaleString(),
  },
  date_of_present_appointment: {
    required: true,
    error: false,
    data: moment().toLocaleString(),
  },
  // conviction_details: { required: false, error: false, data: '' },
};

export const activityDefaultData: iFormDefaultData = {
  activity_name: { required: true, error: false, data: "" },
  status: { required: true, error: false, data: "" },
  activity_methodology: { required: true, error: false, data: "" },
  project_id: { required: true, error: false, data: "" },
  description: { required: true, error: false, data: "" },
  objectives: { required: true, error: false, data: "" },
  cost: { required: true, error: false, data: "" },
  account_chart_id: { required: true, error: false, data: "" },
  priority: { required: true, error: false, data: "" },
  activity_account: { required: true, error: false, data: "" },
};

export const appointmentDefaultData: iFormDefaultData = {
  appointment_type: { required: true, error: false, data: "" },
  patient_id: { required: true, error: false, data: "" },
  reminder: { required: false, error: false, data: "" },
  appointment_datetime: { required: true, error: false, data: "" },
  reminder_datetime: { required: false, error: false, data: "" },
  reminder_unit: { required: false, error: false, data: "" },
};

export const prchaseOrderDefaultData: iFormDefaultData = {
  date: { required: true, error: false, data: "" },
  bid_analysis_id: { required: true, error: false, data: "" },
  authorised_by: { required: true, error: false, data: "" },
  notes: { required: true, error: false, data: "" },
};

export const goodsRecievedDefaultData: iFormDefaultData = {
  date: { required: true, error: false, data: "" },
  purchase_order_id: { required: true, error: false, data: "" },
  received_by: { required: true, error: false, data: "" },
  cash_account_id: { required: true, error: false, data: "" },
  expense_account_id: { required: true, error: false, data: "" },
};

export const bidDefaultData: iFormDefaultData = {
  date: { required: true, error: false, data: "" },
  requisition_id: { required: true, error: false, data: "" },
  file: { required: false, error: false, data: "" },
};

export const addPayrollDefaultData: iFormDefaultData = {
  staff_id: { required: true, error: false, data: "" },
  employee_no: { required: true, error: false, data: "" },
  pay_date: { required: true, error: false, data: "" },
  // total_pay: { required: true, error: false, data: '' },
  // deductions: { required: true, error: false, data: '' },
  // net_pay: { required: true, error: false, data: '' },
  // payment_method: { required: true, error: false, data: '' },
  // bank_name: { required: true, error: false, data: '' },
  account_no: { required: true, error: false, data: "" },
  // description: { required: true, error: false, data: '' },
  paid_amount: { required: false, error: false, data: "" },
  // comments: { required: true, error: false, data: '' },
  // is_curring: { required: true, error: false, data: '' },
  recurring_info: { required: false, error: false, data: "" },
  account_chart_id: { required: true, error: false, data: "" },
};

export const categoryDefaultData: iFormDefaultData = {
  name: { required: true, error: false, data: "" },
  description: { required: true, error: false, data: "" },
};

export const changePasswordDefaultData: iFormDefaultData = {
  new_password: { required: true, error: false, data: "" },
  old_password: { required: true, error: false, data: "" },
  new_password_confirmation: { required: true, error: false, data: "" },
};

export const consolidatedDefaultData: iFormDefaultData = {
  clinic_id: { required: true, error: false, data: "" },
  level: { required: true, error: false, data: "" },
  district_id: { required: true, error: false, data: "" },
  start_date: { required: true, error: false, data: "" },
  end_date: { required: true, error: false, data: "" },
  client_name: { required: true, error: false, data: "" },
  program_officer: { required: true, error: false, data: "" },
};

export const editProfileDefaultData: iFormDefaultData = {
  first_name: { required: true, error: false, data: "" },
  last_name: { required: true, error: false, data: "" },
  middle_name: { required: false, error: false, data: "" },
  mobile_phone: { required: true, error: false, data: "" },
  email: { required: true, error: false, data: "" },
  profile_pic: { required: true, error: false, data: "" },
};

export const programDefaultData: iFormDefaultData = {
  category_name: { required: true, error: false, data: "" },
  description: { required: true, error: false, data: "" },
  project_id: { required: true, error: false, data: "" },
};

export const addMemberDefaultData: iFormDefaultData = {
  activity_team_role_id: { required: true, error: false, data: "" },
  member_id: { required: true, error: false, data: "" },
};

export const addDepartmentDefaultData: iFormDefaultData = {
  name: { required: true, error: false, data: "" },
  description: { required: true, error: false, data: "" },
  salary_range: { required: true, error: false, data: "" },
};

export const unitDefaultData: iFormDefaultData = {
  name: { required: true, error: false, data: "" },
  description: { required: true, error: false, data: "" },
};

export const addActivityRoleDefaultData: iFormDefaultData = {
  name: { required: true, error: false, data: "" },
  description: { required: true, error: false, data: "" },
};

export const addActivityParameterDefaultData: iFormDefaultData = {
  name: { required: true, error: false, data: "" },
  description: { required: true, error: false, data: "" },
  requires_approval: { required: true, error: false, data: "0" },
  parameter_type: { required: true, error: false, data: "1" },
};

export const addActivityPlanDefaultData: iFormDefaultData = {
  title: { required: true, error: false, data: "" },
  description: { required: true, error: false, data: "" },
  priority: { required: true, error: false, data: "" },
  start_date: { required: true, error: false, data: new Date().toString() },
  end_date: { required: true, error: false, data: new Date().toString() },
};

export const htmlParseOptions: HTMLReactParserOptions = {
  replace: (domNode: any) => {
    if (domNode.attribs && domNode.attribs.class === "remove") {
      return null;
    }
  },
};

// export const
