import React, { useEffect } from 'react'
import useInvoices from '../../../hooks/useInvoices';
import { EllipsisLoader } from '../../global/MyLoaders';

interface IViewTransactionProps {
  id: number;
}

const ViewTransactions = ({ id }: IViewTransactionProps) => {
  const { getSelectedInvoice, loading, selectedInvoice } = useInvoices();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedInvoice(id), [id]);

  return loading ? <EllipsisLoader /> : (
    <div>
      {selectedInvoice?.payments?.length > 0 ? (
        <table className="table table-bordered">
          <thead>
            <tr className='text-uppercase'>
              <th>#</th>
              <th>Payment BY</th>
              <th>Payment Type</th>
              <th>Paid Amount</th>
              <th>Payment date</th>
            </tr>
          </thead>

          <tbody>
            {selectedInvoice?.payments?.map((item: any) => (
              <tr key={item.id}>
                <td>{selectedInvoice?.id}</td>
                <td>{selectedInvoice?.supplier?.name}</td>
                <td>{item?.payment_type?.name}</td>
                <td>{Number(item?.total_paid).toLocaleString()}</td>
                <td>{item?.date_paid}</td>
              </tr>
            ))}
          </tbody>

        </table>
      ) : (
        <p className="py-4 text-center text-muted tex-lg">There is no payment made yet for this invoice</p>
      )}
    </div>
  )
}

export default ViewTransactions