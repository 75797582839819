import React, { useState } from 'react'
import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import useBudget from '../../../hooks/useBudget';
import ConfirmDelete from '../../global/ConfirmDelete';
import ModalSwitcher from '../../global/ModalSwitcher';
import MyDataTable from '../../global/MyDataTable';
import { EllipsisLoader } from '../../global/MyLoaders';
import AddBudget from './AddBudget';
import AddBudgetItems from './AddBudgetItems';
import EditBudget from './EditBudget';
import ViewBudgetItems from './ViewBudgetItems';

const ListBudgets = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const { refresh, loading, budgets } = useBudget();

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      reorder: true,
      sortable: true
    }, {
      id: 2,
      name: "Name",
      selector: (row) => row.name,
      reorder: true,
      sortable: true,
      grow: 3,
    }, {
      id: 3,
      name: "Start date",
      selector: (row) => row.start_date,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 4,
      name: "End date",
      selector: (row) => row.end_date,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 5,
      name: "Est. Amount",
      selector: (row) => Number(row.exp_amount).toLocaleString(),
      reorder: true,
      sortable: true,
      grow: 3,
      wrap: true,
    },
    {
      button: true,
      cell: (row) => (
        <div className='row'>
          <Link className='btn btn-sm btn-success mr-1' to={`/accounting/budget/view/${row.id}`}><i className='fa fa-eye'></i></Link>
          <button onClick={(e) => {
            e.stopPropagation();

            handleEditClick(row.id);
          }} className='btn btn-sm btn-primary'><i className='fa fa-edit' ></i></button>

          <button className='btn btn-sm btn-danger mx-1' onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(row.id);
          }}><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];
  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  const handleDeleteClick = (id: number) => {
    setOpenModal(3);
    setSelectedId(id);
    setIsOpen(true);
  }


  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = 'edit-budget';
      modalTitle = 'Edit Budget';
      ModalToRender = EditBudget

      break;
    case 3:
      modalId = 'delete-budget';
      modalTitle = '';
      ModalToRender = ConfirmDelete
      break;
    case 4:
      modalId = 'add-budget-items';
      modalTitle = 'Add Budget items';
      ModalToRender = AddBudgetItems
      break;
    case 5:
      modalId = 'view-budget-items';
      modalTitle = 'View Budget items';
      ModalToRender = ViewBudgetItems
      break;
    default:
      modalId = 'add-new-budget';
      modalTitle = 'Add New Budget';
      ModalToRender = AddBudget
      break;
  }

  return (
    <>
      <ModalSwitcher isOpen={isOpen} setIsOpen={setIsOpen} id={modalId} title={modalTitle} component={<ModalToRender id={selectedId} closeModal={handleModalClick} path={`budget/${selectedId}/delete`} refresh={refresh} budgets={budgets} expenseAccount={[]} incomeAccount={[]} />} />

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className='pl-3 row align-items-end'>
                  <button className='btn btn-primary ml-auto mr-3'
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal(0);
                      setIsOpen(true);
                    }}><i className='fa fa-plus'></i>Add new Budget</button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input type="search" className="form-control" placeholder="Search...." />
                </div>
              </div>
            </div>
            {/* table */}
            {loading ? <EllipsisLoader /> : <MyDataTable columns={columns} data={budgets} title="" />}
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ListBudgets