import React, { useEffect } from "react";
import { useLastUrlSegment } from "../../../hooks/useQuery";
import useBudget from "../../../hooks/useBudget";
import DataTable, { TableColumn } from "react-data-table-component";
import { EllipsisLoader } from "../../global/MyLoaders";

const ComparisonDetails = () => {
  const id = useLastUrlSegment();

  const { budgetComparisionDetails, getBudgetComparisionDetails, loading } =
    useBudget();

  useEffect(() => getBudgetComparisionDetails(id), [id]);

  const ExpandedComponent = ({ data }: any) => (
    <table className="table">
      <thead>
        <tr>
          <th>Account</th>
          <th>Debit Amount</th>
          <th>Credit Amount</th>
        </tr>
      </thead>

      <tbody>
        {data?.journal_line?.length > 0 ? (
          data?.journal_line?.map((item: any) => (
            <tr key={item.id}>
              <td>
                {item.account?.account_code + " " + item?.account?.account_name}
              </td>
              <td>
                {item.debit_amount
                  ? Number(item.debit_amount).toLocaleString()
                  : null}
              </td>
              <td>
                {item.credit_amount
                  ? Number(item.credit_amount).toLocaleString()
                  : null}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={3}>No Item</td>
          </tr>
        )}
      </tbody>
    </table>
  );

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "#",
      selector: (row) => row.id,
      reorder: true,
      sortable: true,
      maxWidth: "50px",
    },
    {
      id: 2,
      name: "Date",
      selector: (row) => row.transaction_date,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 3,
      name: "Narrative",
      selector: (row) => row.description,
      wrap: true,
      grow: 2,
    },
    {
      id: 4,
      name: "Ref No",
      selector: (row) => row.ref_no,
      reorder: true,
      sortable: true,
      wrap: true,
    },
  ];

  return loading ? (
    <EllipsisLoader />
  ) : (
    <div>
      <div className="card p-3 mb-10">
        <table className="table table-bordered table-striped">
          <tbody>
            <tr>
              <th>Budget Name</th>
              <td>{budgetComparisionDetails?.name}</td>
              <th>Budgeted Amount</th>
              <td>
                {Number(budgetComparisionDetails?.exp_amount).toLocaleString()}
              </td>
            </tr>

            <tr>
              <th>Start Date</th>
              <td>{budgetComparisionDetails?.start_date}</td>
              <th>End Date</th>
              <td>{budgetComparisionDetails?.end_date}</td>
            </tr>

            <tr>
              <th>Balance</th>
              <td colSpan={3}>
                {budgetComparisionDetails?.balance
                  ? Number(budgetComparisionDetails?.balance).toLocaleString()
                  : budgetComparisionDetails?.balance}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="card p-3 mb-10">
        <div className="row mx-0 w-full justify-content-between">
          <h5>Transactions List</h5>

          <div>{/* <SingleInputDateRange /> */}</div>
        </div>
        <DataTable
          pagination
          highlightOnHover
          columns={columns}
          data={budgetComparisionDetails?.transactions}
          title=""
          progressPending={loading}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      </div>
    </div>
  );
};

export default ComparisonDetails;
