/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Footer = () => {
  return (
    <footer className="p-4">
      <div className="row">
        <div className="col-md-6">
          <div className="text-center text-md-left">
            <p className="mb-0">
              {" "}
              &copy; Copyright <span id="copyright"> 2023</span>.{" "}
              <a href="#"> Chato </a> All Rights Reserved.{" "}
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <ul className="text-center text-md-right">
            <li className="list-inline-item">
              Developed by{" "}
              <a
                href="https://gmtconsults.com/"
                target="_blank"
                rel="noreferrer"
              >
                GMT Consults{" "}
              </a>{" "}
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
