import React, { useEffect, useRef } from "react";
import useAccounts from "../../../hooks/useAccounts";
import { useReactToPrint } from "react-to-print";
import { EllipsisLoader } from "../../global/MyLoaders";
import { Link } from "react-router-dom";

const ListTrailBalance = () => {
  const {
    loading,
    trailBalance,
    getTrailBalance,
    reportStartDate,
    reportEndDate,
  } = useAccounts();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getTrailBalance(), [reportStartDate, reportEndDate]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let creditSum = 0;
  let debitSum = 0;

  return loading ? (
    <EllipsisLoader />
  ) : (
    <>
      <div className="col-xl-12 mb-30">
        <div className="row mx-0 mb-3 justify-content-end">
          <div className="col-xs-12 col-md-3">
            <div className="row justify-content-end">
              <label>Export</label>
              <button
                onClick={handlePrint}
                className="ml-3 btn btn-sm btn-secondary"
              >
                <i className="fa fa-print" />
              </button>
            </div>
          </div>
        </div>
        <table className="table" ref={componentRef}>
          <thead>
            <tr>
              <th className="bg-info text-center text-white py-2" colSpan={4}>
                Trail Balance
              </th>
            </tr>
            <tr>
              <th colSpan={2} className="font-bold">
                Account Name
              </th>
              <th>Debit(shs.)</th>
              <th>Credit(shs.)</th>
            </tr>
          </thead>
          <tbody>
            {trailBalance?.map((item: any) => (
              <React.Fragment key={item.id}>
                <tr>
                  <td colSpan={2} className="font-medium">
                    <Link to={`reports/transaction/${item.id}`}>
                      {item.account_code + " " + item.account_name}
                    </Link>
                  </td>
                  <td className="font-medium">
                    {Number(
                      item.normal_balance_side == 1
                        ? (item.debit_sum ? item.debit_sum : 0) * 1 -
                            (item.credit_sum ? item.credit_sum : 0) * 1
                        : 0
                    ).toLocaleString()}
                  </td>
                  <td className="font-medium">
                    {Number(
                      item.normal_balance_side == 2
                        ? (item.credit_sum ? item.credit_sum : 0) * 1 -
                            (item.debit_sum ? item.debit_sum : 0) * 1
                        : 0
                    ).toLocaleString()}
                  </td>
                </tr>
              </React.Fragment>
            ))}
            <tr>
              <th colSpan={2}>TOTAL</th>
              <th>
                {Number(
                  trailBalance?.reduce((acc: any, currentValue: any) => {
                    const { debit_sum, credit_sum, normal_balance_side } =
                      currentValue;
                    const sum =
                      normal_balance_side == 1
                        ? (debit_sum ? debit_sum : 0) * 1 -
                          (credit_sum ? credit_sum : 0) * 1
                        : 0;

                    return acc + sum;
                  }, 0)
                ).toLocaleString()}
              </th>
              <th>
                {Number(
                  trailBalance?.reduce((acc: any, currentValue: any) => {
                    const { debit_sum, credit_sum, normal_balance_side } =
                      currentValue;
                    const sum =
                      normal_balance_side == 2
                        ? (credit_sum ? credit_sum : 0) * 1 -
                          (debit_sum ? debit_sum : 0) * 1
                        : 0;
                    return acc + sum;
                  }, 0)
                ).toLocaleString()}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ListTrailBalance;
