/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { TableColumn } from 'react-data-table-component';
import { personalDetails } from '../../../constants';
import usePayrolls from '../../../hooks/usePayrolls';
import MyDataTable from '../../global/MyDataTable';
import { EllipsisLoader } from '../../global/MyLoaders';

const PersonalPayrollDetailsTable = () => {

  const { selectedStaffPayroll, loading } = usePayrolls();
  const columnData: TableColumn<any>[] = [
    {
      id: 1,
      name: "Pay Date",
      selector: (row) => row.pay_date,
      reorder: true,
      sortable: true
    },
    {
      id: 2,
      name: "Gross",
      selector: (row) => Number(row.total_pay).toLocaleString(),
      reorder: true,
      sortable: true
    },
    {
      id: 3,
      name: "Deducations",
      selector: (row) => Number(row.deductions).toLocaleString(),
      reorder: true,
      sortable: true
    },
    {
      id: 4,
      name: "Net Pay",
      selector: (row) => Number(row.net_pay).toLocaleString(),
      reorder: true,
      sortable: true
    },
    {
      id: 5,
      name: "Total Paid",
      selector: (row) => Number(row.paid_amount).toLocaleString(),
      reorder: true,
      sortable: true
    },
    {
      id: 6,
      name: "Recurring",
      selector: (row) => row.is_recurring ? row.recurring_info : 'No',
      reorder: true,
      sortable: true
    },
    // {
    //   id: 7,
    //   name: "Pay slip",
    //   cell: (row) => (
    //     <button className='btn btn-sm btn-secondary'><i className='fa fa-file-pdf-o'></i></button>
    //   ),
    //   reorder: true,
    //   sortable: true
    // },
    {
      button: true,
      cell: (row) => (
        <div className='row'>
          <button className='btn btn-sm btn-primary'><i className='fa fa-edit'></i></button>
          <button className='btn btn-sm btn-danger mx-1'><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];
  return loading && selectedStaffPayroll ? <EllipsisLoader /> : (
    <MyDataTable data={selectedStaffPayroll} columns={columnData} title="" />
  )
}

export default PersonalPayrollDetailsTable