import React from 'react'
import ListAccountCharts from '../../components/accounting/accountCharts/ListAccountCharts'
import DashboardWrapper from '../../components/DashboardWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const ChartsOfAccount = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Charts of account' />
        {/* body */}
        <ListAccountCharts />
      </>
    </DashboardWrapper>

  )
}

export default ChartsOfAccount