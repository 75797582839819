import React, { useEffect, useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import useAccounts from '../../../hooks/useAccounts';
import { EllipsisLoader } from '../../global/MyLoaders';

const ListBalancesheet = () => {

  const { loading, balanceSheet, getBalancesheet, balanceSheetLialibility } = useAccounts();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getBalancesheet(), []);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return loading ? <EllipsisLoader /> : (
    <>

      <div className="col-xl-12 mb-30">
        <div className='row mx-0 mb-3 justify-content-end'>
          <div className='col-xs-12 col-md-3'>
            <div className="row justify-content-end">
              <label>Export</label>
              <button onClick={handlePrint} className="ml-3 btn btn-sm btn-secondary">
                <i className='fa fa-print' />
              </button>
            </div>
          </div>
        </div>

        <table className='table' ref={componentRef}>
          <thead>
            <tr>
              <th className='bg-info text-center text-white py-2' colSpan={3}>Balance sheet</th>
            </tr>
            <tr>
              <th colSpan={2} className="font-bold">Assets</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {balanceSheet?.map((item: any) => (
              <React.Fragment key={item.id}>
                <tr>
                  <td colSpan={2} className='font-medium'>{item.sub_cat_name}</td>
                  <td className='font-medium'>{Number(item.amount).toLocaleString()}</td>
                </tr>
                {item?.accounts?.map((account: any) => (
                  <tr key={account?.id}>
                    <td colSpan={2} className='pl-40'>{account?.account_name}</td>
                    <td >{Math.abs(Number(account?.credit_balance)).toLocaleString()}</td>
                  </tr>

                ))}
              </React.Fragment>

            ))}
            <tr>
              <th colSpan={2} className="font-bold">Liabilities and Stockholder's Equity</th>
              <th></th>
            </tr>
            {balanceSheetLialibility?.map((item: any) => (
              <React.Fragment key={item.id}>
                <tr key={item.id}>
                  <td colSpan={2} className='font-medium'>{item.sub_cat_name}</td>
                  <td className='font-medium'>{Number(item.amount).toLocaleString()}</td>
                </tr>
                {item?.accounts?.map((account: any) => (
                  <tr key={account?.id}>
                    <td colSpan={2} className='pl-40'>{account?.account_name}</td>
                    <td >{Math.abs(Number(account?.credit_balance)).toLocaleString()}</td>
                  </tr>

                ))}
              </React.Fragment>

            ))}
          </tbody>
        </table>

      </div>
    </>
  )
}

export default ListBalancesheet