import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import useAssetManagement from "../../hooks/useAssetManagement";
import ConfirmDelete from "../global/ConfirmDelete";
import ModalSwitcher from "../global/ModalSwitcher";
import MyDataTable from "../global/MyDataTable";
import { EllipsisLoader } from "../global/MyLoaders";
import CreateAsset from "./CreateAsset"
import EditAsset from "./EditAsset";


const ListAssets = () => {
  const { assets, loading, refresh } = useAssetManagement();
  const [isOpen, setIsOpen] = useState(false);
  const [localAssets, setLocalAssets] = useState([]);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);

  useEffect(() => {
    setLocalAssets(assets)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, assets]);


  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "Asset Name",
      selector: (row) => <Link className="text-info" to={`/assets/view/${row.id}`} >{row.asset_name}</Link>,
      reorder: true,
      sortable: true
    },
    {
      id: 2,
      name: "Purchase Date",
      selector: (row) => row.purchase_date,
      reorder: true,
      sortable: true
    }, {
      id: 3,
      name: "Purchase Price",
      selector: (row) => Number(row.purchase_cost).toLocaleString(),
      reorder: true,
      sortable: true
    }, {
      id: 4,
      name: "Salvage Value",
      selector: (row) => Number(row.salvage_value).toLocaleString(),
      reorder: true,
      sortable: true
    }, {
      id: 5,
      name: "Expected Age",
      selector: (row) => row.expected_age,
      reorder: true,
      sortable: true
    }, {
      id: 6,
      name: "Appn/Depn",
      selector: (row) => row.depre_appre_id === 0 ? "Not Applicable" : row.depre_appre_id === 1 ? "Appreciation" : "Deppreciation",
      reorder: true,
      sortable: true
    }, {
      id: 7,
      name: "Status",
      selector: (row) => row.status_id === 1 ? 'Active' : 'Dormant',
      reorder: true,
      sortable: true
    },
    {
      button: true,
      cell: (row) => (
        <div className='row'>
          <button className='btn btn-sm btn-primary' onClick={(e) => {
            e.stopPropagation();
            handleEditClick(row.id);
          }}><i className='fa fa-edit'></i></button>

          <button className='btn btn-sm btn-danger mx-1' onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(row.id);
          }} ><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    const results = assets.filter((res: any) => String(res?.value_amount).toLocaleLowerCase().includes(String(value).toLocaleLowerCase()) || String(res?.asset_name).toLocaleLowerCase().includes(String(value).toLocaleLowerCase()));
    setLocalAssets(results);
  }

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }


  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = 'edit-asset';
      modalTitle = 'Edit asset';
      ModalToRender = EditAsset

      break;
    case 3:
      modalId = 'delete-asset';
      modalTitle = '';
      ModalToRender = ConfirmDelete
      break;
    default:
      modalId = 'add-new-asset';
      modalTitle = 'Add new asset';
      ModalToRender = CreateAsset
      break;
  }

  return (
    <>
      <ModalSwitcher isOpen={isOpen} setIsOpen={setIsOpen} id={modalId} title={modalTitle} component={<ModalToRender id={selectedId} closeModal={handleModalClick} path={`assets/${selectedId}/delete`} refresh={refresh} />} />

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className='pl-3 row align-items-end'>
                  <button className='btn btn-primary ml-auto mr-3' onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(true);
                  }}>Add new asset</button>

                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input type="search" className="form-control" placeholder="Search...." onChange={handleSearch} />
                </div>
              </div>
            </div>
            {/* table */}
            {loading ? <EllipsisLoader /> : <MyDataTable columns={columns} data={localAssets} title='' />}
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ListAssets