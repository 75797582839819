import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import usePayrolls from '../../../hooks/usePayrolls';
import { EllipsisLoader } from '../../global/MyLoaders';

const GeneratePayroll = () => {
  const [formLoading, setLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const { getGeneratedPayroll, generatedPayroll, loading, refreshGeneratedPayroll } = usePayrolls();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getGeneratedPayroll(), []);

  const localDate = new Date();
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const data = { month: localDate.getMonth() + 1 }
    setLoading(true);
    doCreate('payrolls/generate', data).then((response) => {
      if (response.data.success) {
        toast.success('New payroll information was successfully generated', successToastOptions);
        // refresh();
        getGeneratedPayroll();
      } else {
        toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
      }
    }).catch(() => {
      toast.error(ERRORMESSAGE, errorToastOptions);

    }).finally(() => {
      setLoading(false);
    })

  }

  const handleApproval = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const data = { month: localDate.getMonth() + 1 }
    setApproveLoading(true);
    doCreate('payrolls/generate/approve', data).then((response) => {
      if (response.data.success) {
        toast.success('Payroll approved successfully, waiting for payout', successToastOptions);
        // refresh();
        refreshGeneratedPayroll();
      } else {
        toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
      }
    }).catch(() => {
      toast.error(ERRORMESSAGE, errorToastOptions);

    }).finally(() => {
      setApproveLoading(false);
    })

  }

  return (
    <div>
      <h5>Generate Payroll</h5>
      <div className="form-group form-row">
        <select className='form-control col-8'>
          <option disabled={localDate.getMonth() !== 0} value="0">January</option>
          <option disabled={localDate.getMonth() !== 1} value="1">February</option>
          <option disabled={localDate.getMonth() !== 2} value="2">March</option>
          <option disabled={localDate.getMonth() !== 3} value="3">April</option>
          <option disabled={localDate.getMonth() !== 4} value="4">May</option>
          <option disabled={localDate.getMonth() !== 5} value="5">June</option>
          <option disabled={localDate.getMonth() !== 6} value="6">July</option>
          <option disabled={localDate.getMonth() !== 7} value="7">August</option>
          <option disabled={localDate.getMonth() !== 8} value="8">September</option>
          <option disabled={localDate.getMonth() !== 9} value="9">October</option>
          <option disabled={localDate.getMonth() !== 10} value="10">November</option>
          <option disabled={localDate.getMonth() !== 11} value="11">December</option>
        </select>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit} >
          {formLoading ? "Generating..." : "Generate"}
        </button>

      </div>

      {/* list available upaid list */}
      {loading ? <EllipsisLoader /> : generatedPayroll.length > 0 && (
        <div className='mt-2'>
          <div className='text-right py-4'>
            {generatedPayroll[0].status_id === 1 && <button disabled={approveLoading} onClick={handleApproval} className='btn btn-success mr-3'> <i className='fa fa-check-circle'></i> {approveLoading ? 'Approving...' : 'Approve'}</button>}

            {generatedPayroll[0].status_id === 2 && <button className='btn btn-primary mr-3'> <i className='fa fa-money'></i> Payout</button>}
            <button className='btn btn-info'> <i className='fa fa-download'></i> Download</button>
          </div>
          <table className='table'>
            <thead>
              <tr>
                <th>#ID</th>
                <th>Employee Name</th>
                <th>Net Pay</th>
                <th>Total Deductions</th>
              </tr>
            </thead>
            <tbody>
              {generatedPayroll?.map((payroll: any) => (
                <tr key={payroll?.id}>
                  <td>{payroll?.id}</td>
                  <td>{`${payroll?.payroll?.staff?.user?.first_name} ${payroll?.payroll?.staff?.user?.last_name}`}</td>
                  <td>{`${Number(payroll?.payroll?.paid_amount).toLocaleString()}`}</td>
                  <td>{Number(payroll?.payroll?.deductions).toLocaleString()}</td>
                </tr>

              ))}
            </tbody>
          </table>
        </div>
      )}

    </div>
  )
}

export default GeneratePayroll